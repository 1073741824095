<template>
  <div>
    <!--    条件查询-->
    <div style="display: flex;margin-bottom: 10px;">
      <el-input clearable style="width: 150px;margin-right: 30px;margin-left: 20px" size="small" v-model="byname" placeholder="请输入名字"/>
      <el-date-picker
          v-model="bytime"
          type="date"
          placeholder="选择日期"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          style="margin-right: 50px"
          size="small"
      >
      </el-date-picker>
      <el-button type="primary" size="mini" @click="getall">搜索</el-button>
    </div>
    <!--    培训过的所有人列表-->
    <div>
      <vxe-table
          :align="allAlign"
          :data="tableData">
        <vxe-column type="seq" title="序号" width="60"></vxe-column>
        <vxe-column field="lecturer" width="240" title="讲师姓名"></vxe-column>
        <vxe-column field="content" width="340" title="培训内容"></vxe-column>
        <vxe-column field="uptime" width="140" title="培训时间"></vxe-column>
        <vxe-column field="lastgrade" width="140" title="培训得分"></vxe-column>
        <vxe-column title="操作" show-overflow>
          <template #default="{ row }">
            <vxe-button size="mini" status="primary" @click="gtepeograde(row)">查看明细</vxe-button>
            <vxe-button size="mini" status="primary" @click="getSelectionEvent(row)">总体评价</vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
      <vxe-pager
          style="margin-top: 20px"
          :current-page="tablePage1.currentPage"
          :page-size="tablePage1.pageSize"
          :total="tablePage1.totalResult"
          :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
          @page-change="handlePageChange1">
      </vxe-pager>
    </div>
    <!--    明细列表-->
    <el-dialog
        v-model="mx"
        :title="teacher"
        width="60%"
    >
      <div style="display: flex;justify-content: center">
        <div>
          <el-tag type="danger">评分人数: {{ peonum }}</el-tag>
        </div>
        <div style="margin-left: 15px">
          <el-tag type="danger">最高分: {{ maxgrade }}</el-tag>
        </div>
        <div style="margin-left: 15px">
          <el-tag type="danger">最低分: {{ mingrade }}</el-tag>
        </div>
      </div>
      <div style="margin-top: 30px">
        <vxe-table
            :align="allAlign"
            :data="mxData">
          <vxe-column type="seq" title="序号" width="60"></vxe-column>
          <vxe-column field="que" width="500" title="问题"></vxe-column>
          <vxe-column field="grade" width="300" title="得分"></vxe-column>
        </vxe-table>
      </div>
    </el-dialog>
<!--    每人个人打分情况-->
    <el-dialog
        v-model="peograde"
        :title="teacher"
        width="60%"
    >
      <vxe-table
          :align="allAlign"
          :data="peogradeData">
        <vxe-column type="seq" title="序号" width="60"></vxe-column>
        <vxe-column field="post" title="岗位"></vxe-column>
        <vxe-column field="lastgrade" title="评分"></vxe-column>
        <vxe-column title="操作" show-overflow>
          <template #default="{ row }">
            <vxe-button size="mini" status="primary" @click="gteonepeograde(row)">查看明细</vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </el-dialog>
<!--    每人打分详细-->
    <el-dialog
        v-model="onepeograde"
        :title="teacher"
        width="60%"
    >
      <vxe-table
          :align="allAlign"
          :data="onepeogradeData">
        <vxe-column type="seq" title="序号" width="60"></vxe-column>
        <vxe-column field="question" width="500" title="问题"></vxe-column>
        <vxe-column field="gradenum" width="300" title="得分"></vxe-column>
      </vxe-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "gradeall",
  data() {
    return {
      allAlign: 'center',
      tableData: [],
      mx: false,
      peonum: '',
      maxgrade: '',
      mingrade: '',
      mxData: '',
      teacher: '',
      tablePage1: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0
      },
      byname: '',
      bytime: '',
      peograde: false,
      peogradeData: [],
      onepeograde: false,
      onepeogradeData: []
    }
  },
  created() {
    this.getall();
  },
  methods: {
    getall() {
      if (this.bytime === null){
        this.bytime = '';
      }
      this.axios.get('/imdgrade/getall', (response) => {
        this.tablePage1.totalResult = response.obj.obj.length;
        this.tableData = response.obj.obj.slice((this.tablePage1.currentPage - 1) * this.tablePage1.pageSize, this.tablePage1.currentPage * this.tablePage1.pageSize);
      },{
        name: this.byname,
        time: this.bytime
      })
    },
    handlePageChange1({currentPage, pageSize}) {
      this.tablePage1.currentPage = currentPage
      this.tablePage1.pageSize = pageSize
      this.getall()
    },
    getSelectionEvent(row) {
      this.mx = true;
      this.teacher = row.lecturer + '-' + row.content + '-' + row.uptime + '-得分明细';
      this.axios.post('/imdgrade/grademx', (response) => {
        this.peonum = response.obj.peonum;
        this.maxgrade = response.obj.maxgrade;
        this.mingrade = response.obj.mingrade;
        this.mxData = response.obj.gradeMxVos;
      }, {
        flag: row.flag
      })
    },
    gtepeograde(row){
      this.teacher = row.lecturer + '-' + row.content + '-' + row.uptime + '-得分明细';
      this.peograde = true;
      this.axios.post('/imdgrade/peograde', (response) => {
        this.peogradeData = response.obj;
        console.log(this.peogradeData)
      },{
        flag: row.flag
      })
    },
    gteonepeograde(row){
      console.log(row);
      this.onepeograde = true;
      this.axios.post('/imdgrade/onepeograde', (response) => {
        this.onepeogradeData = response.obj;
        console.log(this.onepeogradeData)
      },{
        mainid: row.id
      })
    }
  }
}
</script>

<style scoped>

</style>