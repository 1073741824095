<template>
  <div style="background-color: #F2F2F2">
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">培训评分</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div id="grade_div1">
      <Gradeall></Gradeall>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
import Gradeall from "@/views/imd/grade/gradeall"
export default {
  components: {Header, Gradeall},
  name: "grade"
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  justify-content: space-between;
}
#grade_div1{
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;
  width: 95%;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 500px;
}
</style>